
import {Component, Mixins, Prop} from 'vue-property-decorator';
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {UyapDigerEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapDigerEvrakTuru";
import MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm.vue";
import AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm.vue";
import DosyadaHacizliMalinSatisiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadaHacizliMalinSatisiTalepEvrakForm.vue";
import DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm.vue";
import RehinAcigiBelgesiHazirlanmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehinAcigiBelgesiHazirlanmasiTalepEvrakForm.vue";
import TasinmazHaczininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/TasinmazHaczininKaldirilmasiTalepEvrakForm.vue";
import HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm.vue";
import DosyaninIslemdenKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninIslemdenKaldirilmasiTalepEvrakForm.vue";
import DosyadakiAvansinIadesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiAvansinIadesiTalepEvrakForm.vue";
import DosyadakiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import KiymetTakdirininYapilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/KiymetTakdirininYapilmasiTalepEvrakForm.vue";
import HaricenTahsilatBildirimiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HaricenTahsilatBildirimiTalepEvrakForm.vue";
import DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm.vue";
import HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm.vue";
import BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm.vue";
import Iik150cSerhiEklenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik150cSerhiEklenmesiTalepEvrakForm.vue";
import AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm.vue";
import RehininParayaCevrilmesiSerhiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehininParayaCevrilmesiSerhiTalepEvrakForm.vue";
import MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm.vue";
import Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm.vue";
import BankadanHacizliParaninIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BankadanHacizliParaninIstenilmesiTalepEvrakForm.vue";
import YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  computed: {
    genelEvrakTuru() {
      return UyapDigerEvrakTuru;
    }
  },
  components: {
    YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm,
    BankadanHacizliParaninIstenilmesiTalepEvrakForm,
    Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm,
    HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm,
    MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm,
    RehininParayaCevrilmesiSerhiTalepEvrakForm,
    AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm,
    Iik150cSerhiEklenmesiTalepEvrakForm,
    BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm,
    HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm,
    DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm,
    HaricenTahsilatBildirimiTalepEvrakForm,
    KiymetTakdirininYapilmasiTalepEvrakForm,
    DosyadakiHacizlerinKaldirilmasiTalepEvrakForm,
    DosyadakiAvansinIadesiTalepEvrakForm,
    DosyaninIslemdenKaldirilmasiTalepEvrakForm,
    HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm,
    TasinmazHaczininKaldirilmasiTalepEvrakForm,
    RehinAcigiBelgesiHazirlanmasiTalepEvrakForm,
    DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm,
    DosyadaHacizliMalinSatisiTalepEvrakForm,
    AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm,
    MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm,
    NewFormWrapper
  }
})
export default class TopluGenelTalepDuzenleForm extends Mixins(ObjectInputMixin) {
  @Prop() talepler!: Array<AvukatTalepListCevapRow>;

  showTalep(talepKod: UyapDigerEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }
}
